import _ from 'lodash';
import Cookies from 'js-cookie';

export function loadItemsFromCookie(obj, itemPaths) {
  _.forEach(itemPaths, (path, cookieKey) => {
    try {
      const value = Cookies.get(cookieKey);
      if (value !== undefined) {
        _.set(obj, path, JSON.parse(value));
      }
    } catch (error) {
      //ignore parse errors
      console.warn(`Failed to parse cookie - ${cookieKey}`);
    }
  });
}

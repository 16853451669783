import _ from 'lodash';

export function envLoader(prefix: string): any {
  return _.fromPairs(
    _.map(
      _.filter(_.toPairs(process.env), ([key, value]) => key.startsWith(prefix)),
      ([key, value]) => [key.slice(prefix.length), value]
    )
  );
}

// Unique undefined value
class Nil {}

export function setEnvConfig(target): any {
  const envVars = envLoader('REACT_APP_CONFIG_');
  _.forEach(_.toPairs(envVars), ([k, v]) => {
    const path = k.replace('_', '.');
    if (_.get(target, path, Nil) === Nil) {
      // Config target isn't aware of this value - probably wrong key
      throw new Error(`Unknown env config path - ${k}  [with value ${v}]`);
    }
    // Valid path - assign to it
    _.set(target, path, v);
  });
}

import { Organization, Tenant, Project, Environment } from 'types/baseTypes';

export enum API_TYPE {
  MOCK = 1,
  REST,
}

export interface ApiClientOptions {
  /**
   * Url of the backend api
   */
  url: string;
  /**
   * Either real client or mock
   */
  apiType: API_TYPE;
  /**
   * access token to use with the api (if null, client will be logged out)
   */
  accessToken?: string;
  /**
   * The active organization. if null, some queries will not work.
   */
  activeOrganization?: Organization;
  /**
   * The active tenant. if null, some queries will not work.
   */
  activeTenant?: Tenant;
  /**
   * The active project. if null, some queries will not work.
   */
  activeProject?: Project;
  /**
   * The active environment. if null, some queries will not work.
   */
  activeEnvironment?: Environment;
}

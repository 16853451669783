import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { lazy } from 'react';
import reportWebVitals from './reportWebVitals';
import config from './config/Config';
import { createRoot } from 'react-dom/client';

if (
  config.sentry.dsn !== undefined &&
  config.sentry.env !== undefined &&
  config.sentry.release !== undefined &&
  config.sentry.dsn.length > 0 &&
  config.sentry.env.length > 0 &&
  config.sentry.release.length > 0
) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.env,
    release: config.sentry.release,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}
const EmbedApp = lazy(() => import('./EmbedApp'));
const NewApp = lazy(() => import('./App'));
const App = window?._env_?.BUILD_TARGET_EMBED ? EmbedApp : NewApp;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  // <StrictMode>

  <App />
  // <EmbedApp />
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
